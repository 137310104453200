import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Pagination, Select, Spin } from 'antd';

import PropTypes from 'prop-types';

import './sprintPlan.css';
import CoreTable from '../../components/CoreTable/CoreTable';
import { TYPES } from '../../components/CoreTable/config';
import { getSprintPlanDataRequest } from '../../core/agileProject/agileProjectActions';
import { defaultPageSize, pageSizeOptions } from '../../core/utils/values';

const SprintPlan = ({ onUpdate, view }) => {
    const { settings, selectedSprintPlan, sprintDatPlanPagination, isFetching, sprints } = useSelector((state) => state?.agileProject);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const viewId = parseInt(searchParams.get('view_id'), 10);
    const [viewSprintId, setViewSprintId] = useState(viewId || null);
    const [initialValue, setInitialValue] = useState(sprints.find((sprint) => sprint?.id === Number(viewId))?.name);
    const [data, setData] = useState();
    const [selected, setSelected] = useState(initialValue || '');
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const { id } = useParams();
    const parsedId = parseInt(id, 10);
    const [filterInfo, setFilterInfo] = useState(null);
    const [sorterInfo, setSorterInfo] = useState(null);
    const handleFilterChange = (filter) => {
        setFilterInfo(filter);
    };

    const handleSorterChange = (sorter) => {
        setSorterInfo(sorter);
    };

    const handleChangePage = (pageNumber) => {
        dispatch(getSprintPlanDataRequest({ projectId: parsedId, sprintId: viewSprintId, page: pageNumber, pageSize }));

    };
    const handleChangePageSize = (current, size) => {

        setPageSize(size);
        dispatch(getSprintPlanDataRequest({ projectId: parsedId, sprintId: viewSprintId, pageSize: size }));

    };
    const showTotal = (total) => `Results: ${sprintDatPlanPagination?.from} - ${sprintDatPlanPagination?.to} of ${total}`;
    // A2 page size
    const printStyles = `@media print {
        @page {
            size: 594mm 420mm;
            margin: 0mm;
        }
        body {
            background-color: #ffffff !important;
        }
        .ant-table .ant-table-container::before,
        .ant-table .ant-table-container::after {
            width: 0px !important;
        }
        .printable {
            display: block !important;
        }
        .not-printable {
            display: none !important;
        }
        .printable-content {
            padding-top: 10mm;
            padding-right: 20mm;
            padding-left: 20mm;
            padding-bottom: 10mm;
            background-color: #ffffff !important;
        }
    }`;

    useEffect(() => {
        onUpdate({
            viewId: parseInt(viewId, 10),
        });
        setData(selectedSprintPlan.find(sprint => sprint.sprintId === parseInt(viewId, 10))?.data);
    }, [selected, initialValue, viewId, viewSprintId]);

    useEffect(() => {
        setData(selectedSprintPlan.find(sprint => sprint.sprintId === parseInt(viewId, 10))?.data);
    }, [selectedSprintPlan]);

    useEffect(() => {
        setSelected(initialValue);
    }, [settings, initialValue]);

    useEffect(() => {
        if (viewId) {
            setViewSprintId(viewId);
            setSelected(sprints.find((sprint) => sprint?.id === parseInt(viewId, 10))?.name);
            setInitialValue(sprints.find((sprint) => sprint?.id === parseInt(viewId, 10))?.name);
        } else {
            setSelected(null);
        }
    }, [viewId]);

    const items = sprints.map((sprint) => ({
        label: (
            <Link key={sprint.id} to={`/report/${settings?.id}?view=${view}&view_id=${sprint.id}`}>
                <div>{sprint.name}</div>
            </Link>
        ),
        key: sprint.id,
        name: sprint.name,
        value: sprint.id,
    }));

    const onSelect = ({ key }) => {
        setViewSprintId(key);
        setSelected(sprints.find((sprint) => sprint?.id === parseInt(key, 10))?.name);
        setInitialValue(sprints.find((sprint) => sprint?.id === parseInt(key, 10))?.name);
    };

    const filterOption = (input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div style={{ margin: 20, marginTop: 30 }}>
            {view === 'sprint-plan'
                && (
                    <>
                        <h2>Select a Sprint</h2>
                        <Select
                            style={{
                                width: '100%',
                            }}
                            labelInValue
                            placeholder="Select a Sprint"
                            showSearch
                            onSelect={onSelect}
                            options={items}
                            disabled={isFetching}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            value={initialValue} />
                        <h3 style={{ marginBottom: 20 }}>
                            {settings && (
                                <>
                                    {!settings && <Spin style={{ marginLeft: 10 }} size="small" />}
                                </>
                            )}
                        </h3>
                        { selected && settings && (
                            <>
                                <CoreTable
                                    type={TYPES.SPRINT_PLAN}
                                    dataSource={data}
                                    isFetching={isFetching}
                                    printable
                                    printStyles={printStyles}
                                    projectName={settings.projectName}
                                    clientName={settings.clientName}
                                    selected={selected}
                                    filterInfo={filterInfo}
                                    handleFilterChange={handleFilterChange}
                                    sorterInfo={sorterInfo}
                                    handleSorterChange={handleSorterChange} />
                                <div className="sm-seperator" />
                                <div className="custom-pagination-container">
                                    {sprintDatPlanPagination?.total > 0 && (
                                        <Pagination
                                            showSizeChanger
                                            pageSizeOptions={pageSizeOptions}
                                            current={sprintDatPlanPagination.current}
                                            total={sprintDatPlanPagination.total}
                                            pageSize={sprintDatPlanPagination.page_size}
                                            showTotal={showTotal}
                                            onChange={handleChangePage}
                                            onShowSizeChange={handleChangePageSize} />
                                    )}
                                </div>
                            </>

                        )}
                    </>

                )}
        </div>
    );
};

SprintPlan.propTypes = {
    onUpdate: PropTypes.func,
    view: PropTypes.string,
    viewId: PropTypes.number,
};

SprintPlan.defaultProps = {
    onUpdate: null,
    view: null,
    viewId: null,
};

export default SprintPlan;
