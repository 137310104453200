import React, { useContext } from 'react';
import { Card, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { CloseCircleFilled, PlusCircleFilled } from '@ant-design/icons';

import { ProjectContext } from '../ProjectProvider';
import './style.scss';
import view, { colors, criteriaNameDefinitions, scoreStatus } from '../../core/utils/values';
import projectTypes from '../../core/utils/projectTypes';

const CardComponent = ({ projects }) => {
    const { setProject } = useContext(ProjectContext);
    const renderProjectType = (type) => {
        const foundProjectType = projectTypes.find((projectType) => type === projectType.value);

        if (foundProjectType) {
            if (type === 10) {
                return 'Waterfall';
            }
            return foundProjectType.name;
        }

        return 'Unknown Project Type';
    };
    // Ensure that reason is properly parsed
    const calculateStatus = (status) => {
        if (status === colors.COLOR_RED) {
            return 'ffb8b8';
        }
        if (status === colors.COLOR_AMBER) {
            return 'fbffcc';
        }
        if (status === colors.COLOR_GREEN) {
            return 'ffffff';
        }
        return 'ffffff';
    };
    const tooltipMessage = (healthStatus) => {
        if (healthStatus?.length === 0 || !healthStatus) {
            return (
                <ul style={{ paddingLeft: '20px', margin: 0, color: 'black' }}>
                    <li>No data available for project health status</li>
                </ul>
            );
        }
        return (
            <ul style={{ paddingLeft: '20px', margin: 0, color: 'black' }}>
                {healthStatus?.map((status) => <li key={status?.id}>{criteriaNameDefinitions[status?.criteria_name] || (status?.criteria_name) } {scoreStatus[status?.status]}</li>)}
            </ul>
        );
    };
    return (
        <div className="card-container">
            {projects.map((project) => {
                const { id } = project;
                const status = calculateStatus(project?.health_status_score);
                return (
                    <div className="card" key={id}>
                        <Link to={`/report/${project.id}?view=${view[0].view}`}>
                            <Tooltip color={`#${status}`}
                                title={tooltipMessage(project?.health_status)}>

                                <Card
                                    onClick={() => setProject(project)}
                                    style={{
                                        borderRadius: 10,
                                        backgroundColor: `#${status}`,
                                        color: 'black',
                                    }}
                                    hoverable
                                    className="card-component">
                                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'self-start' }}>
                                        <div />

                                        <h2 className="card-code">{project.code}</h2>
                                        <div className="colour_circle" style={{ backgroundColor: '#fffff', justifySelf: 'flex-start', borderWidth: 0 }}>
                                            { !project?.status ? (
                                                <CloseCircleFilled style={{ color: '#d11208', aspectRatio: 1, fontSize: 25.5 }} />
                                            ) : (
                                                <PlusCircleFilled style={{ color: '#14d948', aspectRatio: 1, fontSize: 25.5 }} />
                                            )}
                                        </div>
                                    </div>
                                    <h2 className="card-name">{project.name}</h2>
                                    <p className={!project?.project_manager ? 'card-error' : null}>PM: {project?.project_manager?.name || 'Undefined'}</p>
                                    <p className={!project?.lead_dev ? 'card-error' : null}>LD: {project?.lead_dev?.name || 'Undefined'}</p>
                                    <p className="card-agile-text" style={{ color: 'black' }}>{renderProjectType(project?.type)}</p>
                                </Card>
                            </Tooltip>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

CardComponent.propTypes = {
    projects: PropTypes.array,
};

CardComponent.defaultProps = {
    projects: null,
};

export default CardComponent;
