const ObjectFilter = (dataIndex, dataSource) => (
    {
        filters: dataSource?.map(record => ({
            text: record[dataIndex]?.name,
            value: record[dataIndex]?.name,
        })).filter((record, index, array) => array.findIndex((s) => record?.text === s?.text) === index),
        onFilter: (value, record) => record[dataIndex]?.name === value,
        filterMode: 'tree',
    }
);

export default ObjectFilter;
