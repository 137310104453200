import React from 'react';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, RightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { toHours } from '../../core/utils/time';
import { colors, colorsHex, projectType } from '../../core/utils/values';

const renderImage = (data) => {
    if (data) {
        return (
            <img
                style={{ width: '25px', height: '25px' }}
                src={data?.icon_url}
                alt={data?.name} />
        );
    }

    return '';
};

const renderProjectUrl = (data) => {
    if (data) {
        return (
            <a
                href={`/report/${data?.id}?view=overview`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Open report overview">
                <RightOutlined />
            </a>
        );
    }

    return '';
};

const renderList = (data) => {
    if (data) {
        const names = data?.map(d => d?.name);
        return names.join(', ');
    }

    return '';
};

const renderUnorderedList = (data) => {
    if (!data) {
        return 'No Data Available';
    }
    return (
        <ul style={{ paddingLeft: '20px', margin: 0, color: 'black' }}>
            {data.map((reason) => (
                <li><ReactMarkdown>{reason}</ReactMarkdown></li>
            ))}
        </ul>
    );
};

const renderStatus = (data, criteriaName) => {
    let circleColor = null;
    let icon = null;
    let criteriaFound = false;
    let listContent = null;
    let reason = null;
    let updatedAt = null;
    if (data?.length === 0 || !data) {
        return 'No Data Available';
    }
    for (let i = 0; i < data.length; i++) {
        const status = data[i];
        if (status?.criteria_name === criteriaName) {
            criteriaFound = true;
            reason = status?.reason;
            updatedAt = status?.recorded_at;
            if (status?.status === colors.COLOR_GREEN) {
                circleColor = colorsHex.COLOR_GREEN;
                icon = <CheckCircleFilled style={{ color: circleColor, aspectRatio: 1, fontSize: 20 }} />;
            } else if (status?.status === colors.COLOR_RED) {
                circleColor = colorsHex.COLOR_RED;
                icon = <CloseCircleFilled style={{ color: circleColor, aspectRatio: 1, fontSize: 20 }} />;
            } else if (status?.status === colors.COLOR_AMBER) {
                circleColor = colorsHex.COLOR_AMBER;
                icon = <ExclamationCircleFilled style={{ color: circleColor, aspectRatio: 1, fontSize: 20 }} />;
            }
            break;
        }
    }
    if (!criteriaFound) {
        return 'No Data Available';
    }
    if (reason) {
        listContent = (
            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <p><span style={{ fontWeight: 'bold' }}>Feedback:</span></p>
                <ul style={{ paddingLeft: '20px', margin: 0, color: 'white' }}>
                    {reason?.map((item) => (
                        <li><ReactMarkdown>{item}</ReactMarkdown></li>
                    ))}
                </ul>
                {updatedAt && (
                    <p style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>Last updated:</span> {moment(updatedAt).format('DD/MM/YYYY')}</p>
                )}
            </div>
        );
    }

    return (
        <Tooltip color={circleColor}
            title={listContent}>
            {icon}
        </Tooltip>
    );
};

const renderProjectType = (data) => {
    if (!data) {
        return 'N/A';
    }
    return projectType[data] || 'N/A';
};

const renderPeople = (record) => {
    const { lead_dev, developers, project_manager, sales_manager } = record;
    return (
        <div>
            <p>Lead Dev: {lead_dev?.name || 'N/A'}</p>
            <p>Developers: {Array.isArray(developers) ? developers.map((d) => d.name).join(', ') : 'N/A'}</p>
            <p>Sales: {sales_manager?.name || 'N/A'}</p>
            <p>PM: {project_manager?.name || 'N/A'}</p>
        </div>
    );
};

const renderLink = (data) => (
    <a href={`https://scorchsoft.atlassian.net/browse/${data}`}>
        { data }
    </a>
);

const renderHours = (data) => {
    if (data) {
        return toHours(data);
    }

    return 0;
};

const renderPeriod = (data) => {
    if (moment(data, true).isValid()) {
        return moment(data).format('DD/MM/YYYY');
    }
    if (moment(data, 'YYYY-MM-DD', true).isValid()) {
        return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return data;
};

const renderInitals = (data) => {
    if (data) {
        return (
            data.split(' ').map(part => part.charAt(0)).join('')).toUpperCase();
    }

    return '';
};

const renderInitialsFromArray = (names) => {
    // Check if names is an array and has at least one element
    if (Array.isArray(names) && names.length > 0) {
        // Map through names array and convert each name to initials
        return names.map((name) => name
            .split(' ')
            .map((part) => part.charAt(0))
            .join('')
            .toUpperCase())
            .join(', '); // Join initials with a comma and space
    }

    // Return an empty string if names is not a valid array
    return '-';
};

export { renderImage, renderProjectUrl, renderList, renderUnorderedList, renderStatus, renderProjectType, renderPeople, renderLink, renderHours, renderPeriod, renderInitals, renderInitialsFromArray };
