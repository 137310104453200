import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Form, Modal, Segmented, Space } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom';

import './report.css';

import moment from 'moment';
import { isNull } from 'underscore';
import Main from '../../components/layout/Main';
import { exportSpecDocRequest, getPevInitialValuesRequest, getPevLatestSnapshotRequest, getRetainerOverviewRequest, getSprintDataRequest, getSprintPlanDataRequest, getTicketsRequest, updateDataSourceRequest } from '../../core/agileProject/agileProjectActions';
import Overview from '../Overview';
import ProjectSettings from '../ProjectSettings';
import SprintTable from '../SprintTable';
import IssueTable from '../IssueTable';
import { getDashProjectsRequest } from '../../core/dashboard/dashboardActions';
import BillingPeriod from '../BillingPeriod';
import SprintPlan from '../SprintPlan';
import PevDataTable from '../PevDataTable';
import useUser from '../../core/user/useUser';
import AgileBugRateAnalysis from '../BugRateAnalysis';
import BugRateAnalysis from '../WaterfallProject/BugRateAnalysis';
import { ProjectContext } from '../../components/ProjectProvider';
import useCan from '../../core/utils/useCan';
import Permissions from '../../core/utils/permissions';
import PullRequest from '../PullRequest';

const Report = () => {
    const dispatch = useDispatch();
    const { logoutRequest } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const view = searchParams.get('view');
    const { project } = useContext(ProjectContext);
    const { dashboardUrl } = useSelector((state) => state?.dashboard || {});
    const { currentProject: selectedProject, isExporting: isExportingSpec } = useSelector((state) => state.projectSettings || {});
    const [projectCode, setProjectCode] = useState();
    const { can } = useCan();
    const goBack = () => {
        if (isNull(dashboardUrl) || dashboardUrl === 'null=') {
            navigate('/dashboard');
        } else {
            navigate(`/dashboard?${dashboardUrl}`);
        }
    };

    const { settings, isFetching, isRefreshing, lastRefreshedAt, lastFetchedAt, updatedProject, statusUpdate } = useSelector((state) => state?.agileProject || {});
    const { projectData } = useSelector((state) => state?.dashboard || []);

    const { id } = useParams();
    const parsedId = parseInt(id, 10);
    const parsedViewId = parseInt(searchParams.get('view_id'), 10);
    const currentProject = projectData?.length > 0 ? projectData?.find((p) => p.id === parsedId) : null;
    const [isLoading, setIsLoading] = useState(isRefreshing);
    const [isExporting, setIsExporting] = useState(false);
    const [projectName, setProjectName] = useState();
    const [data, setData] = useState({ viewId: parsedViewId });

    const updateData = ((details) => {
        setData(details);
    });

    const tabs = [
        ...(can(Permissions.VIEW_OVERVIEW) ? [{ label: 'Overview', url: 'overview', component: <Overview /> }] : []),
        ...(can(Permissions.VIEW_ISSUE_BREAKDOWN) ? [{ label: 'Issue Breakdown', url: 'issue-breakdown', component: <IssueTable /> }] : []),
        ...((currentProject?.agile && can(Permissions.VIEW_SPRINT_BREAKDOWN)) ? [{ label: 'Sprint Breakdown', url: 'sprint-breakdown', component: <SprintTable onUpdate={updateData} /> }] : []),
        ...((currentProject?.agile && can(Permissions.VIEW_BILLING_PERIOD_BREAKDOWN)) ? [{ label: 'Billing Period Breakdown', url: 'billing-period-breakdown', component: <BillingPeriod view={view} viewId={data?.viewId} /> }] : []),
        ...((currentProject?.agile && can(Permissions.VIEW_SPRINT_PLAN)) ? [{ label: 'Sprint Plan', url: 'sprint-plan', component: <SprintPlan onUpdate={updateData} view={view} viewId={parseInt(data?.viewId, 10)} /> }] : []),
        ...(can(Permissions.VIEW_BUG_RATE_ANALYSIS) ? [{ label: 'Bug Rate Analysis', url: 'bug-rate-analysis', component: currentProject?.agile ? <AgileBugRateAnalysis /> : <BugRateAnalysis /> }] : []),
        ...(can(Permissions.VIEW_OVERVIEW) ? [{ label: 'Pull Requests', url: 'pull-requests', component: <PullRequest /> }] : []),
        ...((currentProject?.agile && can(Permissions.VIEW_PEV_TAB)) ? [{ label: 'PEV Data Table', url: 'pev', component: <PevDataTable /> }] : []),
        ...(can(Permissions.VIEW_SETTINGS) ? [{ label: 'Settings', url: 'settings', component: <ProjectSettings project={project || selectedProject} isExistingProject /> }] : [])];

    const initialTab = tabs.find((tab) => tab.url === view)?.label || 'Overview';
    const initialTable = tabs.find((tab) => tab.url === view)?.component || <Overview />;

    const [value, setValue] = useState(initialTab);
    const [table, setTable] = useState(initialTable);
    const [fixVersionModalOpen, setFixVersionModalOpen] = useState(false);
    const [form] = Form.useForm();
    useEffect(() => {
        dispatch(getDashProjectsRequest());
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setIsLoading(isRefreshing);
    }, [isRefreshing]);

    useEffect(() => {
        setIsExporting(isExportingSpec);
    }, [isExportingSpec]);
    useEffect(() => {
        if (projectData) {
            setData({ viewId: parsedViewId });
        }
        if (projectCode && projectName && parsedViewId) {

            if (view === 'sprint-breakdown') {
                dispatch(getSprintDataRequest({
                    sprintId: parsedViewId,
                    projectId: parsedId,
                }));
            }
            if (view === 'sprint-plan') {
                dispatch(getSprintPlanDataRequest({
                    sprintId: parsedViewId,
                    projectId: parsedId,
                }));
            }
        }
    }, [parsedViewId, projectData]);

    useEffect(() => {
        if (projectData && !isFetching) {
            setProjectCode(currentProject?.code);
            setProjectName(currentProject?.name);

            if (projectCode) {
                dispatch(getRetainerOverviewRequest({
                    projectId: parsedId,
                }));
                dispatch(getTicketsRequest({
                    projectId: parsedId,
                }));
            }
        }
    }, []);

    useEffect(() => {
        if (projectData && projectCode && !isFetching) {
            if (parsedId !== settings?.id) {
                dispatch(getRetainerOverviewRequest({
                    projectId: parsedId,

                }));
                dispatch(getTicketsRequest({
                    projectId: parsedId,
                }));
                setProjectCode(currentProject?.code);
                setProjectName(currentProject?.name);
                dispatch(getPevLatestSnapshotRequest({ projectId: parsedId }));
                dispatch(getPevInitialValuesRequest({ projectId: parsedId }));
            }
        }
    }, [projectData, parsedId]);

    useEffect(() => {
        setValue(initialTab);
        setTable(initialTable);
        setData({});
    }, [view]);

    const handleLogout = () => {
        logoutRequest();
    };

    const handleRefreshPress = () => {
        dispatch(updateDataSourceRequest({
            projectCode,
            projectName,
            parsedId }));
        dispatch(getPevInitialValuesRequest({ projectId: parsedId }));
        dispatch(getPevLatestSnapshotRequest({ projectId: parsedId }));
    };

    const handleExportPress = () => {
        setFixVersionModalOpen(true);
    };

    const handleExportFinish = (values) => {
        const selectedFixVersions = values.fixversions;
        dispatch(exportSpecDocRequest({ projectId: parsedId, fixVersions: selectedFixVersions }));
        setFixVersionModalOpen(false);
        form.resetFields();
    };
    useEffect(() => {
        if (projectCode && updatedProject === parsedId) {
            dispatch(getRetainerOverviewRequest({
                projectId: parsedId,

            }));
        }
    }, [updatedProject, parsedId]);

    const segments = tabs.map((tab) => {
        const linkTo = `/report/${parsedId}?view=${tab.url}`;

        return {
            label: (
                <Link key={tab.label} to={linkTo}>
                    <div>{tab.label}</div>
                </Link>
            ),
            value: tab.label,
        };
    });

    function formatTime(time) {
        if (!time) {
            return 'N/A';
        }

        if (moment(time, 'YYYY-MM-DD HH:mm:ss').isValid()) {
            return moment(time, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
        }

        return time;
    }

    function backLink() {
        return (
            <div style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                <Button onClick={goBack}>
                    <LeftOutlined /> Dashboard
                </Button>
                <div style={{ flexDirection: 'row', display: 'flex', verticalAlign: 'center', gap: 20 }}>
                    <Button
                        loading={isLoading}
                        type="primary"
                        onClick={handleRefreshPress}>Refresh Project Data
                    </Button>
                    <Button
                        loading={isExporting}
                        type="primary"
                        onClick={handleExportPress}>Export Specification doc
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <>
            {isFetching && (
                <div style={{ width: '100%', position: 'absolute', zIndex: 2, height: '100%', alignItems: 'center', display: 'flex', backgroundColor: 'rgb(0, 0, 0, 0.1)', justifyContent: 'center' }} />
            )}
            <Main
                title={projectCode}
                floatingHeader={false}
                showHeader
                showDates
                refreshedDate={formatTime(lastRefreshedAt)}
                fetchedDate={formatTime(lastFetchedAt)}
                statusUpdate={statusUpdate}
                onLogout={handleLogout}>
                { backLink() }
                <Segmented size="large"
                    block
                    options={segments}
                    value={value}
                    onChange={setValue} />
                {table}
            </Main>
            <Modal centered width={540} footer={null} open={fixVersionModalOpen} onOk={() => { setFixVersionModalOpen(false); }} onCancel={() => { setFixVersionModalOpen(false); }}>
                <h4 className="h-4 title-text-md text-center">Select Fix Version(s)</h4>
                <div className="form-wrap">
                    <Form form={form} onFinish={handleExportFinish} layout="vertical">
                        <Form.Item name="fixversions" rules={[{ required: true, message: 'Please select at least one fix version!' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                {settings?.fixVersions?.map((version) => (
                                    <Checkbox key={version.id} value={version.id}>
                                        {version.name}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" className="wide">
                                Generate Report
                            </Button>
                            <Button className="link-green btn-empty underlined" onClick={() => { setFixVersionModalOpen(false); }}>
                                Cancel
                            </Button>
                        </Space>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default Report;
